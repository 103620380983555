<template>
  <section class="blacklist-container">
    <div
      v-if="isBacklist"
      class="flex-justify blacklist-box mg-b-20">
      <span class="flex-center">
        <span>
          <font-awesome-icon
            class="icon"
            icon="times-circle"></font-awesome-icon>
        </span>
        <span class="content-text">{{clientBlackData.message}}</span>
      </span>
      <v-button
        class="button-theme"
        @click="handleClientDetail('0')">
        <span>Client Detail</span>
      </v-button>
    </div>
    <div
      v-if="isClaim"
      class="flex-justify blacklist-box">
      <span class="flex-center">
        <span>
          <span class="error-circle">!</span>
        </span>
        <span class="content-text">{{clientBlackData.claimed_message}}</span>
      </span>
      <v-button
        class="button-theme"
        @click="handleClientDetail('1')">
        <span>Claim Record</span>
      </v-button>
    </div>
  </section>
</template>

<script>
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import {mapActions} from 'vuex'

export default {
  name: 'claimBlackListTip',
  props:{
    clientBlackData:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    isBacklist(){
      let {blacklist=false} = this.clientBlackData||{}
      return blacklist
    },
    isClaim(){
      let {claimed=false} = this.clientBlackData||{}
      return claimed
    },
  },
  methods:{
    ...mapActions('claim',['setClaimTab']),
    handleClientDetail(index){
      this.setClaimTab(index)
      this.$router.push('/member/clients/detail/'+this.clientBlackData.client_id)
    }
  }
}
</script>

<style scoped lang="scss">
.blacklist-container{
  margin-top: 30px;
}
 .blacklist-box{
   border: 1px solid #CBD5E0;
   background: #FFF;
   padding: 10px 20px;

   .icon{
     color: #FF4343;
     text-align: center;
     font-size: 24px;
     font-style: normal;
     font-weight: 900;
     line-height: 150%;
     margin-right: 10px;
   }
   .error-circle{
     color: #fff;
     text-align: center;
     font-size: 24px;
     font-style: normal;
     font-weight: 900;
     line-height: 150%;
     margin-right: 10px;
     display: flex;
     width: 24px;
     height: 24px;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     background: #FF4343;
     border-radius: 50%;
   }
   .content-text{
     color:  #2D3748;
     /* Bold/Small */
     font-family: Inter;
     font-size: 14px;
     font-style: normal;
     font-weight: 700;
     line-height: 150%;
   }
 }
</style>
